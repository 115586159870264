import React from 'react';
import {navigate, Link} from 'gatsby';
import tw, {styled} from 'twin.macro';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import {Heading} from '../../Typography';
import {Container} from '../../Grid';
import {Description, StyledButton} from '../../Page/home/WorkWithUs';
import BookNowController from '../../BookNowController';

const DescriptionText = styled.p`
  font-family: avertaFont;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  max-width: 700px;
  margin-top: 26px;
  margin-bottom: 41px;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
  }
`;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}

  span {
    ${tw`block text-200`}
  }

  margin-bottom: 26px;
  margin-top: 68px;

  @media ${props => props.theme.screens.md} {
  }

  @media ${props => props.theme.screens.lg} {
  }
`;

const ItemContainer = styled.div`
  position: absolute;
  bottom: ${props => props.bottom};
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  width: ${props => props.width};
  right: ${props => props.right};
`;

const List = styled.ul`
  width: 100%;
  margin-top: 30px;
  list-style-type: disc;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-items: ${props => props.alignItems};
  padding-left: ${props => props.paddingLeft};
  @media ${props => props.theme.screens.lg} {
    width: 33%;
    margin-top: 0;
  }
`;

const ListItem = styled.li`
  font-family: avertaFont;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px; /* 163.158% */
  text-decoration-line: underline;
  margin-bottom: 14px;
  max-width: 100%;
  cursor: ${props => props.cursor};

  @media ${props => props.theme.screens.md} {
    max-width: 260px;
  }

  @media ${props => props.theme.screens.lg} {
    max-width: 295px;
  }
`;

const MobileView = styled.div`
  display: flex;
  flex-flow: column wrap;

  @media ${props => props.theme.screens.md} {
    display: none;
  }
`;

const DesktopView = styled.div`
  display: none;

  @media ${props => props.theme.screens.md} {
    display: block;
    position: relative;
  }
`;

const OnlyDesktopView = styled.div`
  display: none;

  @media ${props => props.theme.screens.lg} {
    display: block;
  }
`;

const OnlyTabletView = styled.div`
  display: none;

  @media ${props => props.theme.screens.md} {
    display: block;
  }

  @media ${props => props.theme.screens.lg} {
    display: none;
  }
`;
const createArray = (startingNumber: number, content: string, length = 3) =>
  Array.from({length}, (_, index) => `${content} ${startingNumber + index}`);

const createPragLocations = (text: string) => {
  const locationOne = createArray(1, text);
  const locationTwo = createArray(4, text);
  const locationThree = createArray(7, text);
  const locationFour = createArray(10, text);
  const locationFive = createArray(13, text);

  const locationFirstHalf = createArray(1, text, 9);
  const locationSecondHalf = createArray(10, text, 6);
  const combinedLocations = [...locationFirstHalf, ...locationSecondHalf];

  return {
    locationOne,
    locationTwo,
    locationThree,
    locationFour,
    locationFive,
    locationFirstHalf,
    locationSecondHalf,
    combinedLocations,
  };
};

const ImageItems = ({
  heading,
  description,
  image,
  mobileImage,
  tabletImage,
  items,
  subDescription,
}: any) => {
  const {language} = useI18next();
  const {
    locationOne,
    locationTwo,
    locationThree,
    locationFour,
    locationFive,
    combinedLocations,
    locationFirstHalf,
    locationSecondHalf,
  } = createPragLocations(items.generic);
  const restLocations = [items.item2, items.item3];
  const secondCombined = [...locationSecondHalf, ...restLocations];
  const availablePages = 16;

  const createList = (location: string[], props = {}) => {
    return (
      <List {...props}>
        {location.map((item: string) => {
          const orderArr = item.split(' ');
          const orderNum = parseInt(orderArr[orderArr.length - 1], 10);
          let link =
            language === 'cs'
              ? `/uklidove-sluzby-praha-${orderNum}`
              : `/en/cleaning-services-prague-${orderNum}`;

          link = Number.isNaN(orderNum) ? '' : link;
          if (!link) {
            return (
              <a key={item}>
                <ListItem>{item}</ListItem>
              </a>
            );
          }
          return (
            <a href={link} key={item} target="_blank" rel="noreferrer">
              <ListItem cursor={availablePages > orderNum ? 'pointer' : ''}>
                {item}
              </ListItem>
            </a>
          );
        })}
      </List>
    );
  };
  return (
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <DescriptionText>{description}</DescriptionText>
      <DesktopView>
        <OnlyDesktopView>
          <GatsbyImage image={getImage(image)} style={{width: '100%'}} />
          <div
            style={{display: 'flex', flexFlow: 'column wrap', height: '100%'}}
          >
            <ItemContainer bottom="30%">
              {createList(locationOne)}
              {createList(locationTwo)}
              {createList(locationThree)}
            </ItemContainer>
            <ItemContainer bottom="10%">
              {createList(locationFour)}
              {createList(locationFive)}
              {createList(restLocations, {paddingLeft: '60px'})}
            </ItemContainer>
          </div>
        </OnlyDesktopView>
        <OnlyTabletView>
          <GatsbyImage image={getImage(tabletImage)} style={{width: '100%'}} />
          <ItemContainer bottom="5%" width="50%">
            {createList(locationFirstHalf)}
          </ItemContainer>
          <ItemContainer
            bottom={language === 'cs' ? '6%' : '3%'}
            width="50%"
            right="0%"
          >
            {createList(secondCombined, {
              alignItems: 'flex-start',
              paddingLeft: '50px',
            })}
          </ItemContainer>
        </OnlyTabletView>
      </DesktopView>
      <MobileView>
        <GatsbyImage image={getImage(mobileImage)} style={{width: '100%'}} />
        {createList(combinedLocations)}
      </MobileView>
      <div style={{marginTop: 80, marginBottom: 44}}>
        <Description
          dangerouslySetInnerHTML={{__html: subDescription.content}}
        />
      </div>
      <BookNowController>
        <StyledButton>{subDescription.button}</StyledButton>
      </BookNowController>
    </Container>
  );
};

export default ImageItems;

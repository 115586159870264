import React from 'react';
import tw, {styled} from 'twin.macro';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {Container} from '../../Grid';
import {Heading} from '../../Typography';
import {Description} from '../home/WorkWithUs';
import SVGIcon from '../../SVGIcon';
import useWindowSize from '../../../hooks/useWindowSize';

const Text = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 15px;
  max-width: 195px;

  @media ${props => props.theme.screens.lg} {
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
  }
`;

const Icon = styled.div`
  ${tw`flex justify-center items-center`}
  width: 35px;
  height: 35px;
  background: #eff7ee;
  border-radius: 100%;
`;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}
  margin-bottom: 27px;

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-top: 140px;
  height: 100%;

  @media ${props => props.theme.screens.lg} {
    flex-flow: row wrap;
  }
`;

const Box = styled.div`
  width: 100%;

  @media ${props => props.theme.screens.lg} {
    width: 60%;
    padding-left: 100px;
    padding-right: ${props => props.paddingRight};
  }
`;

const ImageBox = styled.div`
  width: 100%;
  margin-bottom: 40px;
  height: 400px;
  height: ${props => props.height};
  @media ${props => props.theme.screens.lg} {
    width: 40%;
    margin-bottom: 0;
    padding-right: ${props => props.paddingRight};
    height: 710px;
    max-height: 710px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-items: ${props => props.alignItems};
`;

const Column = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

const MarginBox = styled.div`
  margin-bottom: 42px;
  @media ${props => props.theme.screens.md} {
    margin-top: ${props => props.marginTop};
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRight};
    margin-bottom: ${props => props.marginBottom};
  }

  @media ${props => props.theme.screens.lg} {
    margin-bottom: ${props => props.marginBottomLg};
  }
`;

const ImageText = ({background, backgroundTablet, content}) => {
  const createList = (content: string) => content.split(';');
  const list = createList(content.items);
  const firstList = list.slice(0, 4);
  const secondList = list.slice(4).reverse();
  const size = useWindowSize();
  const CUSTOM_BREAKPOINT = 975;
  const isTablet = size?.width <= CUSTOM_BREAKPOINT && size?.width > 480;

  return (
    <Container>
      <Wrapper>
        <ImageBox height={isTablet ? "600px" : "400px"}>
          <GatsbyImage
            image={getImage(isTablet ? backgroundTablet : background)}
            imgStyle={{borderRadius: 16, height: '100%'}}
            style={{height: '100%'}}
          />
        </ImageBox>
        <Box paddingRight="46px">
          <StyledHeading>{content.heading}</StyledHeading>
          <Description>{content.description}</Description>
          <MarginBox marginBottomLg="96px" marginBottom="45px" />
          <Row alignItems="baseline">
            <Column>
              {firstList.map(item => (
                <MarginBox key={item} marginBottom="26px">
                  <Row>
                    <Icon>
                      <SVGIcon icon="regular-check" />
                    </Icon>
                    <Text>{item}</Text>
                  </Row>
                </MarginBox>
              ))}
            </Column>
            <Column>
              {secondList.map(item => (
                <MarginBox key={item} marginBottom="26px" marginLeft="100px">
                  <Row>
                    <Icon>
                      <SVGIcon icon="regular-check" />
                    </Icon>
                    <Text>{item}</Text>
                  </Row>
                </MarginBox>
              ))}
            </Column>
          </Row>
        </Box>
      </Wrapper>
    </Container>
  );
};

export default ImageText;
